@mixin scroll {
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  & {
    scrollbar-width: auto;
    scrollbar-color: $primary #ffffff;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary;
  }
}

@mixin hide-scroll {
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    display: none;
  }
}

