@import "fonts";
@import "variables";
@import "../node_modules/rfs/scss.scss";
.text-formatted-body,
.text-formatted,
.ck-editor,
.ck {

  /* rtl:options: {
        "autoRename": true
      } */
  &:not(.contact-us) {
    h1,
    .ck-heading_heading1 span {
      color: $primary;
      @include rfs(38px);
      font-family: Somer;
    }
    h2,
    .ck-heading_heading2 span {
      color: $primary;
      @include rfs(34px);
      font-family: Somer;
    }

    h3,
    .ck-heading_heading3 span {
      @include rfs(26px);
      color: $primary;
      font-family: Somer;
    }

    h4,
    .ck-heading_heading4 span {
      color: $primary;
      font-family: Somer;
      @include rfs(22px);
    }

    h5,
    .ck-heading_heading5 span {
      @include rfs(20px);
      color: $primary;
      font-family: Somer;
    }
    p {
      @include rfs(22px);
      margin: 14px 0;
    }
    a:not(.btn) {
      color: $primary;
      text-decoration: underline;
    }
    iframe {
      max-width: 100%;
    }

    .field--name-field-media-oembed-video {
      iframe {
        width: 767px;
        height: 500px;
        @screen max-tablet {
          height: auto;
        }
      }
    }

    ol {
      counter-reset: item;
      li {
        display: block;
        &:before {
          color: $black;
          content: counters(item, ".") ". ";
          counter-increment: item;
        }
      }
    }
    video {
      max-width: 100%;
      height: auto;
    }
    blockquote {
      padding: 20px 0;
      text-align: center;
      border-left: 2px solid $gray;
      background-color: $border-color;
      p {
        font-family: Somer;
        text-transform: uppercase;
        @include rfs(26px);
        line-height: 26px;
      }
      & + p {
        padding-bottom: 33px;
        font-size: 35px;
        line-height: 35px;
        font-weight: 300;
        width: 100%;
        text-align: center;
      }
    }

    .text-align-center {
      text-align: center;
    }
    .text-align-right {
      text-align: right #{"/*!rtl:right*/"};
      border: 0;
    }
    .align-center {
      text-align: center;
    }
    .align-left {
      text-align: left #{"/*!rtl:left*/"};
      float: left #{"/*!rtl:left*/"};
      margin-right: 10px #{"/*!rtl:ignore*/"};
      border: 0;
    }
    .align-right {
      float: right #{"/*!rtl:right*/"};
      margin-left: 10px #{"/*!rtl:ignore*/"};
      text-align: right #{"/*!rtl:right*/"};
      border: 0;
    }
    .text-align-left {
      text-align: left #{"/*!rtl:left*/"};
      border: 0;
    }
    table {
      display: table;
      max-width: 100%;
      overflow-x: auto;
      border: 1px solid $table-bg-100;

      td,
      th {
        padding: 10px 30px;
        text-align: left;
      }

      tr {
        color: $black;
      }
      thead {
        font-weight: 700;
        background-color: #f5f7fa;
        tr {
          td {
            border-inline-end: 1px solid #e4e7eb;
          }
        }
      }
      tbody {
        tr {
          color: $black;
          &:not(:last-child) {
            border-bottom: 1px solid $table-bg-100;
          }
          &:not(td:last-child) {
            td {
              border-inline-end: 1px solid #e4e7eb;
            }
          }
        }
      }
      @screen max-mobile {
        display: block;
        overflow-x: auto;
        max-width: 100%;
      }
    }
  }

  .jumbotron {
    a {
      margin-bottom: 8px;
    }
  }
}
.alert {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 10px 20px;
  &.alert-danger {
    background-color: rgba($danger, 0.25);
    border-color: $red;
    color: $red;
  }
  &.alert-primary {
    background-color: rgba($color: $primary, $alpha: 0.25);
    border-color: $primary;
    color: $primary;
  }

  &.alert-secondary {
    background-color: rgba($color: $secondary, $alpha: 0.25);
    border-color: $secondary;
    color: $secondary;
  }
  &.alert-info {
    background-color: rgba($color: $blue-300, $alpha: 0.25);
    border-color: $blue-300;
    color: $blue-300;
  }
  &.alert-warning {
    background-color: rgba($color: $orange, $alpha: 0.25);
    border-color: $orange;
    color: $orange;
  }

  &.alert-dark {
    background-color: rgba($color: $black, $alpha: 0.25);
    border-color: $black;
    color: $black;
  }
  &.alert-light {
    background-color: rgba($color: $border-color, $alpha: 0.25);
    border-color: $border-color;
    color: $border-color;
  }
}
.btn {
  padding: 10px 24px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  &:hover {
    opacity: 0.8;
  }
  &.btn-outline-primary {
    background: transparent;
    border-color: $primary;
    color: $primary;
  }
  &.btn-outline-secondary {
    background: transparent;
    border-color: $secondary;
    color: $secondary;
  }
  &.btn-outline-success {
    background: transparent;
    border-color: $green;
    color: $green;
  }
  &.btn-outline-warning {
    background: transparent;
    border-color: $orange;
    color: $orange;
  }
  &.btn-outline-light {
    background: transparent;
    border-color: $gray;
    color: $gray;
  }
  &.btn-outline-info {
    background: transparent;
    border-color: $blue-300;
    color: $blue-300;
  }
  &.btn-outline-danger {
    background: transparent;
    border-color: $red;
    color: $red;
  }
  &.btn-outline-dark {
    background: transparent;
    border-color: $black;
    color: $black;
  }
  &.btn-primary {
    background: $primary;
    border-color: $primary;
    color: $white;
  }
  &.btn-secondary {
    background: $secondary;
    border-color: $secondary;
    color: $white;
  }
  &.btn-success {
    background: $green;
    border-color: $green;
    color: $white;
  }
  &.btn-warning {
    background: $orange;
    border-color: $orange;
    color: $white;
  }
  &.btn-light {
    background: $gray;
    border-color: $gray;
    color: $white;
  }
  &.btn-info {
    background: $blue-300;
    border-color: $blue-300;
    color: $white;
  }
  &.btn-danger {
    background: $red;
    border-color: $red;
    color: $white;
  }
  &.btn-dark {
    background: $black;
    border-color: $black;
    color: $white;
  }
}

table {
  width: 100%;
  tr {
    &.table-primary {
      background-color: rgba($color: $primary, $alpha: 0.25);
    }
    &.table-secondary {
      background-color: rgba($color: $secondary, $alpha: 0.25);
    }
    &.table-success {
      background-color: rgba($color: $green, $alpha: 0.25);
    }
    &.table-warning {
      background-color: rgba($color: $orange, $alpha: 0.25);
    }
    &.table-light {
      background-color: rgba($color: $border-color, $alpha: 0.25);
    }
    &.table-info {
      background-color: rgba($color: $blue-300, $alpha: 0.25);
    }
    &.table-danger {
      background-color: rgba($color: $red, $alpha: 0.25);
    }
    &.table-dark {
      background-color: rgba($color: $black, $alpha: 0.25);
      color: $white;
    }
  }
  &.table-dark {
    background-color: rgba($color: $black, $alpha: 0.25);
    color: $white;
  }
  &.thead-light {
    background-color: rgba($color: $border-color, $alpha: 0.25);
    color: $black;
  }
  &.thead-dark {
    background-color: rgba($color: $black, $alpha: 0.25);
    color: $white;
  }
  &.table-striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          * {
            --bs-table-color-type: var(--bs-table-striped-color);
            --bs-table-bg-type: var(--bs-table-striped-bg);
          }
        }
      }
    }
  }
  &.table-bordered {
  }
  &.table-hover {
  }
  &.table-sm {
  }
  &.table-responsive {
  }
}
