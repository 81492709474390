
    @import "variables";
    @import "mixin";
    
.container {
  position: absolute;
  top: 10%;
  left: 23%;
  width: 50%;
}




.hide {
  max-height: 200px;
  height: auto;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 70px;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95) 30%, transparent 100%);
  }
}
