
    @import "variables";
    @import "mixin";
    
.radioCheck {
  padding: 0;
}

.radioInput {
  &:checked + label {
    cursor: auto;
    .bigCircle {
      border-color: $secondary;
    }
    .smallCircle {
      background-color: $primary;
      opacity: 1;
    }
  }
}

.radioLabel {
  display: flex;
  padding: 14px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
  &:hover,
  &:focus {
    .bigCircle {
      border-color: $secondary;
    }
    .smallCircle {
      opacity: 1;
    }
  }
}

.circleWrapper {
  margin-left: auto;
  padding-left: 15px;
}

.bigCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $white;
  border: 1px solid $border-color;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $gray;
  opacity: 0;
}
