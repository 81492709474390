.swiper {
  position: unset !important;
  display: grid !important;
  grid-auto-flow: column;
  z-index: 1;
  .swiper-slide {
    height: 100%;
    article {
      height: 100%;
    }
  }
  .pagenation-wrapper {
    position: absolute;
    right: 0;
    top: -75px;
    z-index: 1;
    height: unset;
    button {
      svg {
        stroke: theme("colors.blue");
      }
      &.prev-arrow {
        margin-right: 32px;
      }
      &:disabled {
        cursor: not-allowed;
        svg {
          stroke: theme("colors.light-blue");
        }
      }
    }
  }
}

.padding-medium-both {
  padding: 40px 0;
  @screen max-mobile {
    padding: 20px 0;
  }
}
.padding-medium-top {
  padding-top: 40px;
  @screen max-mobile {
    padding-top: 20px;
  }
}
.padding-medium-bottom {
  padding-bottom: 40px;
  @screen max-mobile {
    padding-bottom: 20px;
  }
}

.padding-large-both {
  padding: 80px 0;
  @screen max-mobile {
    padding: 40px 0;
  }
}
.padding-large-top {
  padding-top: 80px;
  @screen max-mobile {
    padding-top: 40px;
  }
}
.padding-large-bottom {
  padding-bottom: 80px;
  @screen max-mobile {
    padding-bottom: 40px;
  }
}

.background-grey {
  background: #f5f7fa;
}

[data-radix-popper-content-wrapper] {
  width: 100%;
}

.html-gray-filter {
  filter: grayscale(1);
}
.section-gap {
  gap: 30px;
}
.landing-field-wrapper {
  border: 1px solid #e4e7eb;
  border-radius: 8px;
  background-color: $white;
  .landing-field:not(:last-child) {
    border-bottom: 1px solid #f5f7fa;
  }
}

.exposed-filters {
  .swiper {
    margin: 0;
    width: 66%;
    .swiper-wrapper {
      width: 100%;
    }
    .swiper-slide {
      width: fit-content !important;
    }
  }
  .category-filters {
    position: relative;
    .pagenation-wrapper {
      position: absolute;
      right: 0;
      top: calc(50% + 12px); // the padding of the wrapper
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  @screen max-mobile {
    .swiper {
      width: 100%;
    }
    .category-filters {
      .pagenation-wrapper {
        display: none;
      }
    }
  }
}

.equalizer {
  article {
    height: 100%;
  }
  & > div {
    height: 100%;
  }
}
.newsletter-wrapper {
  @screen laptop {
    padding-top: 125px;
  }
}

.custom-block {
  position: relative;
  .swiper {
    padding: 0 3px;
  }
  .swiper-slide {
    padding-bottom: 15px;
  }
}

.homepage-mission-block {
  .paragraph-default {
    padding-left: 110px;
    padding-right: 110px;

    .paragraph-link {
      min-width: 240px;

      a {
        display: block;
        text-align: center;
      }
    }
    @screen max-tablet {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.section-pattern {
  & .view-block {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-image: url("../images/icons/section-pattern.svg");
      background-repeat: repeat;
      z-index: 2;
      height: 24px;
    }

    &.container {
      &::before {
        left: 15px;
        right: 15px;
        width: auto;
      }
      .swiper-slide {
        padding-bottom: 0;
      }
    }
  }
}
.mission-link {
  background: #e0cfc4;
  position: relative;
  padding: 4px 36px 4px 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: #b77b56;

  &::after {
    content: "";
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    background-image: url("../images/icons/link.png");
    background-repeat: no-repeat;
    z-index: 2;
    height: 18px;
    width: 18px;
  }
}
.map-wrapper {
  iframe {
    max-width: 100%;
  }
}

.table-wrapper {
  position: relative;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  border-radius: 8px;
  border: 1px solid $table-bg-100;
}
[type="checkbox"] {
  width: 24px;
  height: 24px;
  color: $blue;
}
.white-icon {
  path {
    fill: white;
  }
}

@media print {
  .print-page {
    display: block;
  }
  .page-accessories {
    display: none;
  }
  .navbar {
    justify-content: center;
    .navbar-left {
      display: none;
    }
  }
  .desktop-menu,
  .shadow-main-submenu,
  .navbar,
  .header,
  .pagenation-wrapper,
  .pagination-wrapper,
  .custom-pagination,
  .newsletter-wrapper,
  .comments-wrapper,
  .footer,
  .related {
    display: none !important;
  }
  .image-shadow-blue,
  .image-shadow-black {
    all: unset !important;
    &::after {
      all: unset !important;
    }
  }
}
summary::marker {
  display: none;
}
summary::-webkit-details-marker {
  display: none;
}