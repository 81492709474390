body {
  color: theme("colors.black");
  font-family: "Somer";
  font-weight: 500;
  @include font-size(26px);
  margin: 0 !important;
}

hr {
  opacity: 1;
  margin: 0;
  width: 100%;
  background: $gray;
}

a {
  text-decoration: none;
}

picture {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  &.large {
    @include font-size(90px);
  }
  &.title {
    @include font-size(48px);
  }
  &.medium {
    @include font-size(40px);
  }

  &.small {
    @include font-size(28px);
  }
  &.extra-small {
    @include font-size(26px);
  }

  &.blue {
    color: theme("colors.blue");
  }
}
button {
  line-height: 1;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.table-wrapper {
  table {
    $block_padding: 10px 24px;
    width: 100%;
    font-size: 24px;
    line-height: 1.1em;
    white-space: nowrap;
    thead {
      font-weight: 700;
      background-color: $table-bg-50;
      th {
        padding: $block_padding;
        text-align: start;
      }
      :not(th:last-child) {
        border-inline-end: 1px solid $table-bg-100;
      }
    }
    tbody {
      background-color: $white;
      tr {
        border-bottom: 1px solid $table-bg-100;
        td {
          padding: $block_padding;
          white-space: nowrap;
          font-weight: 400;
          text-align: start;
        }
        > :not(td:last-child) {
          border-inline-end: 1px solid $table-bg-100;
        }
      }
      > :not(tr:last-child) {
        border-bottom: 1px solid $table-bg-100;
      }
      .slot-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .slotted-item {
        padding-inline-start: 24px;
      }
      td {
        span:empty {
          display: block;
          width: 100%;
          height: 1px;
          background-color: $table-color;
        }
      }
      :empty:after {
        content: "";
        width: 50%;
        height: 1px;
        background-color: $table-color;
        display: block;
      }
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
