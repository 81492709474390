
    @import "variables";
    @import "mixin";
    
.dropdown {
  position: relative;
  flex: none;
  .dropdownMenu {
    z-index: 10;
    max-height: 350px;
    margin-top: 15px;
    right: 0;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    padding: 0;
    overflow: hidden;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
  .dropdownInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    @media (max-width: 1200px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .dropdownToggle {
        width: 100%;
      }
    }
    & > span {
      font-size: 16px;
      line-height: 25px;
      font-weight: 400;
      color: $black;
    }
  }
  .searchField {
    padding: 0 20px;
    min-width: 250px;
    margin-top: 15px;
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }

  .itemWrapper {
    @include scroll;

    overflow-y: scroll;
    height: 100%;
    max-height: 270px;
    padding: 20px 10px;
    min-width: 235px;
    .dropDownItem {
      .dropDownRadio {
        padding: 0 10px;
        border-radius: 5px;
        &.checked {
          background-color: $gray;
        }
      }
    }
  }
}

.dropdownToggle {
  padding: 5px 15px;
  background-color: $gray;
  border-radius: 20px;
  border: 1px solid $gray !important;
  min-width: 232px;
  height: 40px;
  text-align: start;
  color: $gray;
  position: relative;
  &:focus {
    outline: none;
    box-shadow: none;
    background-color: $gray;
  }
  span {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }
  svg {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.minimalVariant {
  border-radius: 50%;

  .dropdownMenu {
    border-radius: 50%;
  }
}

.whiteDropdownToggle {
  height: 40px;
  background-color: $white;
  border: 1px solid $gray;
  width: 100%;
  font-weight: 400;
  text-align: start;
  position: relative;
  span {
    font-size: 16px;
    line-height: 25px;
  }
  svg {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.dropdownToggle {
  border-radius: 20px;
  height: 40px;
  color: $gray;
  background-color: $gray;

  svg {
    margin-left: 8px;
  }

  &:hover,
  &:focus {
    background-color: $gray;
  }
}

.whiteSelect {
  width: 100%;
  font-size: 16px;

  svg {
    fill: $primary;

    path {
      fill: $primary;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.noOptions {
  text-align: center;
  padding: 15px;
}

.minimalWrapper {
  @include scroll;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  max-height: 270px;
}

.minimalRadio {
  label {
    padding: 14px 20px;
  }

  &:hover,
  &:focus {
    label {
      background-color: $gray;
    }
  }

  input {
    &:checked + label {
      background-color: #14828714;
    }
  }
}

.arrowUp {
  svg {
    width: 11px;
    path {
      fill: #707070;
    }
    transform: rotate(0deg);
  }
}
.arrowDown {
  svg {
    width: 11px;
    path {
      fill: #707070;
    }
    transform: rotate(180deg);
  }
}
