@font-face {
  font-family: "Somer";
  font-display: "swap";
  src: url("../font/Somar/Somar-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Somer";
  font-display: "swap";
  src: url("../font/Somar/Somar-SemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Somer";
  font-display: "swap";
  src: url("../font/Somar/Somar-Medium.otf");
  font-weight: 500;
}


@font-face {
  font-family: "Somer";
  font-display: "swap";
  src: url("../font/Somar/Somar-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Somer";
  font-display: "swap";
  src: url("../font/Somar/Somar-Light.otf");
  font-weight: 300;
}