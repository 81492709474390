
    @import "variables";
    @import "mixin";
    
.other {
  margin-top: 15px;
}

.notOtherValue {
  margin: 0;
}
