
    @import "variables";
    @import "mixin";
    
.exposedFiltersWrapper {
  margin-bottom: 32px;
}

.noResult {
  text-align: center;
  color: $primary;
  font-size: 32px;
  display: flex;
  justify-content: center;
}
