.webform-block,
.solr-search-form,
.comments-block {
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="number"],
  input[type="textfield"],
  textarea {
    border: 1px solid #e4e7eb;
    width: 100%;
    border-radius: 8px;
    @include font-size(26px);
    padding-left: 16px;
    padding-right: 16px;
  }
  .textarea {
    padding: 0;
  }
  label {
    padding-left: 16px;
    @include font-size(22px);
    color: $blue-300;
    font-weight: 400;
    display: block;
    span {
      color: #d62736;
    }
  }
  input::placeholder {
    @include font-size(26px);
    color: $blue-300;
    font-weight: 400;
  }
  button[type="submit"] {
    margin-left: auto;
  }
}

.select-wrapper {
  position: relative;
  max-width: fit-content;
  select {
    all: unset;
    padding: 5px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    background-color: $white;
    color: theme("colors.blue-300");
    border: 2px solid theme("colors.light-border");
    min-width: 200px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
  }
  &::before {
    content: "";
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: block;
    background: url("../images/icons/arrow-down.svg") no-repeat;
    z-index: 1;
  }
}

input[type="checkbox"],
input[type="radio"] {
  + label {
    display: inline;
  }
}
