
    @import "variables";
    @import "mixin";
    
footer.footer {
  padding-top: 40px;
  background-color: theme("colors.blue");
  grid-auto-flow: row dense;
  display: flex;
  flex-direction: column;
  .container {
    padding: 16px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding-bottom: 85px;
  }
  .footer-top-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    .footer-brand {
      width: 233px;
      height: 54px;
      position: relative;
    }

    @screen tablet {
      gap: 0;
    }
  }
  .news-letter-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    input {
      padding: 8px 14px;
      font-family: "Somer";
      font-size: 20px;
      border-radius: 100px;
      width: 100%;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      ::placeholder {
        color: #bfbfbf;
      }
    }
    button {
      font-size: 22px;
      font-family: "Somer";
      position: absolute;
      right: 0;
      height: 40px;
      &:hover {
        opacity: 1;
        background-color: theme("colors.primary");
      }
    }
    @screen laptop {
      width: 310px;
    }
  }
  .menu-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .social-media-container {
    width: 100%;
    margin-top: 24px;
    justify-content: space-between;
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    flex-direction: row;

    .social-wrapper {
      svg {
        & > * {
          fill: theme("colors.white");
        }
      }
    }
    @screen tablet {
      padding-bottom: 44px;
      align-items: center;
    }
    @screen max-laptop {
      justify-content: center;
      flex-direction: column;

      .social-wrapper {
        flex: 100%;
        justify-content: center;
        > div {
          justify-content: center;
        }
      }
    }
  }
  .vision-wrapper {
    display: flex;
    .digital-gov {
      width: 162px;
      height: 48px;
      position: relative;
      margin-right: 32px;
    }
    .vision {
      width: 77px;
      height: 48px;
      position: relative;
    }
  }
  .contact-us-block {
    .field__label {
      display: none;
    }
    p {
      flex: 100%;
      margin-bottom: 10px;
    }
    .contact-us {
      p {
        &:has(a[href*="tel:"]) {
          direction: ltr #{"/*rtl:ltr*/"};
        }
      }
      a[href*="tel:"] {
        direction: ltr #{"/*rtl:ltr*/"};
      }
      table {
        tr {
          display: flex;
          td {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .field--name-field-image {
        padding-inline-end: 10px;
        .field__item {
          width: 20px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .copy-right {
    width: 100%;
    color: theme("colors.white");
    font-size: 20px;
    background-color: theme("colors.dark-blue");
    position: relative;
    z-index: 2;
    .container {
      padding: 16px;
      .copy-right-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 32px;
        @screen max-tablet {
          justify-content: center;
        }
        @screen max-laptop {
          flex-direction: row-reverse;
        }
        .copy-right-text {
          @screen max-laptop {
            order: 2;
          }
        }
        .copy-right-menu {
          @screen max-laptop {
            order: 1;
          }
        }
        @screen tablet {
          gap: 0;
        }
      }
    }
  }
}
