
    @import "variables";
    @import "mixin";
    
.checkInput {
  border-color: $primary;
  opacity: 0.3;
  margin-right: 12px;
  margin-bottom: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:checked {
    background-color: $primary;
    border-color: $primary;
    opacity: 1;
  }
  &:hover,
  &:focus {
    opacity: 1;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23dddddd' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  }
  &:focus,
  &:active {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.2;
    ~ input {
      pointer-events: none;
    }
  }
}

.other {
  margin-bottom: 8px;
  label {
    display: flex;
    align-items: flex-end;
  }
  input {
    margin-bottom: 0px;
    line-height: 1;
  }
  label {
    input[type="text"] {
      pointer-events: none;
      border: 0;
      border-bottom: 1px solid $gray;
      border-radius: 0;
      width: 50%;
      height: auto;
      &:focus {
        outline: 0;
        box-shadow: unset;
      }
    }
  }
}

.active {
  label {
    input[type="text"] {
      pointer-events: all;
    }
  }
}
