
    @import "variables";
    @import "mixin";
    
.header {
  // laptop:fixed  scroll-down header-pattern top-0 z-10 w-full  min-h-[76px] flex items-center
  top: 0;
  z-index: 11;
  width: 100%;
  min-height: 76px;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  &.not-homepage {
    background-color: transparent;
    @screen max-laptop {
      background-color: theme("colors.white");
    }
  }
  .not-homepage {
    &.header-scroll {
      background-color: theme("colors.white");
    }
  }

  &.homepage {
    &.header-top {
      @screen desktop {
        transform: translateY(-100%);
        opacity: 0;
      }
    }
    background-color: transparent;
    transition: all 300ms ease-in-out;
    opacity: 1;
    position: fixed;
    @screen max-desktop {
      position: sticky;
      background-color: theme("colors.white");
    }
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .navbar-brand {
      width: 175px;
      height: 42px;
      position: relative;
      @screen laptop {
        width: 222px;
        height: 55px;
      }
    }
    padding-top: 16px;
    padding-bottom: 16px;
    &.navbar-base {
      border-bottom: 1px solid theme("colors.blue");
    }
    @screen max-desktop {
      &.navbar-base {
        border-bottom: 0;
      }
    }
    @screen laptop {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .navbar-left {
      flex: 1;
      justify-content: flex-end;
      gap: 20px;
      display: flex;
      align-items: center;
      @screen laptop {
        gap: 24px;
      }
    }
    .mobile-backword {
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        cursor: pointer;
        width: 24px;
        height: 24px;
        @screen max-laptop {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  @screen max-desktop {
    position: sticky;
    border-bottom: 2px solid theme("colors.blue");
    &.not-homepage {
      background-color: theme("colors.white");
    }
  }
}
