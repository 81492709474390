
    @import "variables";
    @import "mixin";
    
.formActions {
  display: flex;
  > button {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.drupalWebform {
  display: flex;
  flex-direction: column;
  button[type="submit"] {
    order: 1;
  }
}
.submitButton {
  display: inline-block;
  width: fit-content;
  padding: 8px 64px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
