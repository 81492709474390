$primary: #b77b56;
$primary-dark: #844823;
$secondary: #9aa5b1;
$gray: #767775;
$white: #ffffff;
$black: #1f2933;
$border-color: #f8f8f8f8;
$green: #5ba517;
$orange: #edc307;
$blue: #2e5156;
$blue-300: #616e7c;
$red: #d50e0e;
$danger: #d50e0e;
$link-color: #5ab1ef;
$table-color: #d2d2d2;
$table-bg-50: #f5f7fa;
$table-bg-100: #e4e7eb;
$calendar-font-primary: #969696;
$calndar-border: #e8e8e8;
