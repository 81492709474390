@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card.image-full.background-blue {
    &::before {
      background-color: theme("colors.blue");
    }
  }
}
@layer utilities {
  @for $i from 1 through 100 {
    .rfs-#{$i} {
      font-size: rfs(#{$i}px);
    }
    .font-scale-#{$i} {
      font-size: calc(#{$i}px + var(--font-size-adder, 0px)) !important;
    }
  }

  .overlayImage {
    &::after {
      content: "";
      background: linear-gradient(0deg, rgba(183, 123, 86, 0.5) 0%, rgba(183, 123, 86, 0.5) 100%);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  .overlay {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      z-index: 1;
    }
  }

  .teaser-pattern {
    &::after {
      content: "";
      background-image: url("../images/icons/teaser-pattern.svg");
      background-repeat: repeat;
      background-size: cover;
      position: absolute;
      left: -1px;
      right: -1px;
      bottom: 0;
      height: 25px;
    }
  }

  .discreption-pattern {
    position: relative;
    &:after {
      content: "";
      background-image: url("../images/icons/description-pattern.svg");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 8px;
      right: 0px;
      bottom: 0px;
      top: 0;
      height: 100px;
      width: 100%;
    }
  }
  .large-right-teaser-pattern {
    &::after {
      content: "";
      background-image: url("../images/icons/large-right-teaser-pattern.svg");
      background-repeat: repeat;
      background-size: cover;
      position: absolute;
      right: -1px;
      bottom: 0;
      width: 22px;
      top: 0px;
      height: 100%;

      @screen max-tablet {
        background-size: auto;
      }
    }

    &.reverse-pattern {
      &:after {
        right: unset;
        left: -1px;
      }
    }
  }
  .green-pattern {
    &::after {
      content: "";
      background: linear-gradient(180deg, #2e5156 50.75%, rgba(46, 81, 86, 0) 121.57%);
      background-repeat: repeat;
      background-size: cover;
      position: absolute;
      right: 0px;
      bottom: 0;
      width: 100%;
      top: 0px;
      height: 100%;
      z-index: 1;
      border-radius: 8px;
    }
  }
  .footer-pattern {
    position: relative;
    &:after {
      content: "";
      background: url("../images/footer-pattern.svg");
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      z-index: 1;
      background-repeat: no-repeat;
      height: 291px;
      bottom: 0;
      @screen max-laptop {
        bottom: calc(53px + 40px);
        height: calc(215px - 20px);
        background-size: cover;
      }
    }
  }
  .page-pattern {
    position: relative;
    &:after {
      content: "";
      background: url("../images/page-pattern.svg");
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      width: 100%;
      opacity: 1;
      display: block;
      transition: all 0.3s ease-in-out;
      z-index: -1;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }
  .service-catalog-overly {
    position: relative;
    &::after {
      content: "";
      //background: linear-gradient(180deg, rgba(46, 81, 86, 0.18) 0%, theme("colors.blue") 100%);
      background: linear-gradient(180deg, rgba(46, 81, 86, 0.18) 0%, #2f5257 100%);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      opacity: 0.7;
    }
  }
  .goal-teaser-pattern {
    position: relative;
    &:after {
      content: "";
      background: url("../images/goal-teaser-pattern.svg");
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      opacity: 1;
      display: block;
      transition: all 0.3s ease-in-out;
      z-index: 0;
      background-repeat: no-repeat;
      pointer-events: none;
      background-size: contain;
      height: 100%;
    }
  }
  .custom-block-pattern {
    &:after {
      content: "";
      background: url("../images/modal-pattern.svg");
      position: absolute;
      left: 0;
      right: 0;
      bottom: 24px;
      height: 20px;
      width: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: 100% 50px;
      @screen max-tablet {
        background-size: auto;
      }
    }
  }
  .contact-us-block {
    &.modal-pattern {
      &:after {
        background-size: auto;
      }
    }
  }
  .modal-pattern {
    position: relative;
    &:after {
      content: "";
      background: url("../images/modal-pattern.svg");
      position: absolute;
      left: 0;
      right: 0;
      bottom: 24px;
      height: 20px;
      width: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: 100% 50px;
      @screen max-tablet {
        background-size: auto;
      }
    }
  }
  .small_teaser-pattern {
    position: relative;
    &:after {
      content: "";
      background: url("../images/modal-pattern.svg");
      position: absolute;
      left: 0;
      right: 0;
      bottom: 24px;
      height: 10px;
      width: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      z-index: 1;
      background-repeat: no-repeat;
    }
  }
  .two-swords-pattern {
    &::after {
      content: "";
      background-image: url("../images/icons/two-sowrds.svg");
      background-repeat: no-repeat;
      background-size: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 70px;
      height: 180px;
    }
  }

  .not-found-pattern {
    &::after {
      content: "";
      background-image: url("../images/icons/not-found-pattern.svg");
      background-repeat: no-repeat;
      position: absolute;
      bottom: 70px;
      width: 40%;
      background-size: contain;
      height: 57px;
      right: 0;
      background-repeat: repeat-x;
      transform: scaleX(-1) #{"/* rtl:unset; */"};
      z-index: -1;
    }
  }

  .exposed-active {
    color: theme("colors.primary");
    font-weight: 600;
    border-bottom: 2px solid theme("colors.primary");
  }
  .tab-active {
    &[data-state="active"] {
      color: theme("colors.primary");
      font-weight: 600;
      border-bottom: 2px solid theme("colors.primary");
    }
  }
  .header-pattern {
    &::after {
      content: "";
      background-image: url("../images/icons/pattern.svg");
      width: 100%;
      position: absolute;
      height: 45px;
      top: 38px;
      background-repeat: no-repeat;
      z-index: -1;
      right: 0;
      @screen max-laptop {
        top: 20px;
        right: 0;
        background-size: cover;
        width: 200px;
      }
    }
  }

  .small-left-teaser-pattern {
    overflow: hidden;
    &::after {
      content: "";
      background-image: url("../images/icons/small-left-teaser-pattern.svg");
      background-repeat: repeat;
      position: absolute;
      left: 0;
      right: -20px;
      bottom: 20px;
      height: 12px;
    }
  }
  .body-small-left-teaser-pattern {
    @extend .small-left-teaser-pattern;
    &::after {
      bottom: 0;
    }
  }
  .checkboxChecked {
    width: 24px;
    height: 24px;
    background-image: url("../images/icons/check.svg");
    color: theme("colors.blue");
    background-color: theme("colors.blue");
  }

  .custom-stroke-blue {
    path {
      stroke: theme("colors.blue");
    }
  }
  .custom-stroke-blue-300 {
    path {
      stroke: theme("colors.blue-300");
    }
  }
  .horse-care-overlay {
    position: relative;
    overflow: hidden;
    .horse-care-link {
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      transition: all 0.4s ease-in-out;
      span {
        font-size: 0;
      }
      svg {
        width: 45px;
        height: 45px;
      }
    }
    .horse-care-image {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.7;
        transition: all 0.6s ease-in-out;
        border-radius: 50%;
      }
    }

    &:hover {
      .horse-care-image {
        &::after {
          transition: all 0.3s ease-in-out;
          border-radius: 50%;
          z-index: 1;
          background-color: theme("colors.primary");
        }
      }
      .horse-care-link {
        z-index: 5;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .landing-border {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: -80px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: theme("colors.light-border");
    }
  }

  select {
    background-image: url("../images/icons/arrow-down.svg");
    background-size: 24px 24px;
    background-position: right 0.5rem center;
    min-height: 50px;
  }

  .image-shadow-blue {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(47, 82, 87, 0.2) 76.63%, theme("colors.blue") 100%);
    }
  }

  .image-shadow-black {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: $black, $alpha: 0.3);
    }
  }
  .text-wrap-pretty {
    text-wrap: pretty;
  }
  .accordion-item {
    &.accordion-item-active::after {
      content: "";
      background-image: url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Minus"><path d="M6 12.5H18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
      width: 40px;
      height: 40px;
      background-color: $primary;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
    &::after {
      content: "";
      background-image: url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Add"><path d="M6 12.5H18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path id="Vector_2" d="M12 18.5V6.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
      width: 40px;
      height: 40px;
      background-color: $primary;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .text-formatted-body {
    ol {
      list-style: decimal;
      padding: inherit;
      padding-inline-start: 40px;
    }
    ul {
      list-style: disc;
      padding: inherit;
      padding-inline-start: 40px;
    }
  }
}
