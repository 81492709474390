
    @import "variables";
    @import "mixin";
    
// General style for all views components.
.equalizer {
  article {
    height: 100%;
  }
  & > div {
    height: 100%;
  }
}
