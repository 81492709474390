.flip-arrows {
  .pswp__button--arrow {
    transform: rotate(180deg);
  }
}
.mobile-dropdown {
  [data-radix-popper-content-wrapper] {
    z-index: 50;
    background: $white;
    height: 100vh !important;
    top: 34px !important;
    @screen max-laptop {
      top: 7px !important;
    }
  }
}
.toast-progress {
  background: #0000 linear-gradient(90deg, $primary 0%, $primary-dark 100%) 0% 0% no-repeat padding-box /*rtl:ignore*/;
}
.toast-wrapper {
  font-size: 24px;
  color: $black;
  font-family: "Somer";
}

.about-us-grid {
  .grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0;
    > div {
      flex: 0 0 40%;
    }
    @screen max-tablet {
      gap: 30px;

      > div {
        flex: 0 0 100%;
      }
    }
  }
}

.contact-us-wrapper {
  @screen tablet {
    padding-right: 80px;
  }
  textarea {
    height: 180px;
  }
}

input,
textarea {
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba($primary, 0.2) !important;
  }
}

.react-tel-input {
  .flag-dropdown {
    height: 50px !important;
    background-color: $white !important;
    width: 70px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .country-list {
    left: 0 !important;
    top: 100% !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}
.react-datepicker-wrapper {
  .react-datepicker {
    border-radius: 8px;
    border: 0;
  }
}
.react-datepicker__header {
  background: theme("colors.primary") !important;
}
.react-datepicker__current-month,
.react-datepicker__day-name {
  color: #fff !important;
  font-size: 20px !important;
  font-family: theme("fontFamily.somer") !important;
}
.react-datepicker__day-name {
  font-weight: 600;
}
.react-datepicker__triangle,
.react-datepicker__tab-loop__start {
  display: none;
}
.react-datepicker__navigation-icon::before {
  border-color: #fff !important;
}
.react-datepicker__header__dropdown::after {
  width: 100% !important;
  background-color: #fff !important;
  margin: 10px 0;
}
.react-datepicker__day {
  color: theme("colors.primary") !important;
  font-size: 20px !important;
  font-family: theme("fontFamily.somer") !important;
  font-weight: 600;
  &.react-datepicker__day--keyboard-selected {
    background-color: theme("colors.primary") !important;
    color: #fff !important;
  }
  &:hover {
    background-color: #c99f85 !important;
    color: theme("colors.white") !important;
  }
}

.react-datepicker-popper {
  z-index: 99 !important;
}

/**
* FullCalendar
*/
.fc {
  table {
    border: 1px solid $calndar-border;
    border-radius: 8px;
    text-align: end;

    border: none;
    text-align: end;

    thead {
      background-color: transparent;
      .fc-scrollgrid-sync-inner {
        text-align: start;
      }
      .fc-col-header-cell-cushion {
        padding: 12px;
        font-size: 26px;
        font-weight: 400;
      }
    }
    tbody {
      .fc-daygrid-day-top {
        color: $calendar-font-primary;
        text-align: start;
        a {
          width: 100%;
        }
        min-height: 200px;
      }
      .fc-daygrid-day.fc-day-today {
        background-color: transparent;
      }
      .fc-daygrid-event-harness,
      .fc-daygrid-day-events {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      tbody {
        .fc-daygrid-day-top {
          min-height: 100px;
        }
      }
    }
  }
}

[dir="ltr"] {
  .large-right-teaser-pattern {
    &:after {
      transform: scaleX(-1);
    }
  }
  .reverse-pattern {
    &:after {
      transform: unset;
    }
  }
}

[dir="rtl"] {
  .large-right-teaser-pattern {
    &:after {
      transform: rotate(0);
    }
  }

  .reverse-pattern {
    &:after {
      transform: scaleX(-1);
    }
  }
}

.select__indicator-separator.css-1u9des2-indicatorSeparator {
  display: none;
}

.select__control.css-13cymwt-control {
  border: none;
  height: 100%;
}
