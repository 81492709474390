
    @import "variables";
    @import "mixin";
    
@import "~rfs/scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts";
@import "tailwind";
@import "variables";
@import "~react-toastify/dist/ReactToastify.css";

@import "mixin";
@import "general";
@import "views";
@import "elements";
@import "override";
@import "webform";
@import "ckeditor";
