.view--photo_gallery-homepage {
  .views-row {
    flex: 1;
    transition: all 0.5s ease-in-out;
    .responsive-image {
      img {
        width: 575px;
        height: 480px !important;
        object-fit: cover;
      }
    }
    &:hover {
      flex: 1.7;
    }

    @screen max-tablet {
      flex: 0 0 100%;
      margin-bottom: 8px;
      .responsive-image {
        img {
          width: unset;
          height: auto !important;
          object-fit: unset;
        }
      }
      &:hover {
        flex: 0 0 100%;
      }
    }
  }
}

.view--news-homepage {
  .swiper {
    padding: 0 3px;
    .pagenation-wrapper {
      top: 0;
    }
  }
  .swiper-slide {
    padding-bottom: 15px;
  }
}
.view--goals-compiled {
  position: relative;
  .pagenation-wrapper {
    position: absolute;
    right: 0;
    z-index: 1;
    height: unset;
    button {
      svg {
        stroke: theme("colors.blue");
      }
      &.prev-arrow {
        margin-right: 32px;
      }
      &:disabled {
        cursor: not-allowed;
        svg {
          stroke: theme("colors.light-blue");
        }
      }
    }
    @screen max-laptop {
      top: 14px;
      .prev-arrow {
        margin-right: 20px;
      }
    }
  }
  .disable-swipe {
    .pagenation-wrapper {
      display: none;
    }
  }
}

.view--slider-homepage {
  .add-padding {
    @screen laptop {
      padding-top: 200px;
    }
  }
}

.view--comments-all {
  .views-row {
    margin-bottom: 30px;
  }
}
