
    @import "variables";
    @import "mixin";
    
.topWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 992px) {
    flex: 0 0 50%;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    border-bottom: 0;
    flex-direction: column;
  }
}

.leftWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 25px;
  .HierarchyElements {
    flex: 0 0 50%;
    @media (max-width: 768px) {
      flex: 0 0 100%;
    }
  }
  .element {
    flex: 0 0 35%;
    margin-right: 20px;
    display: flex;
    @media (max-width: 992px) {
      flex: 1 0 49%;
      margin-right: 0;
      margin-bottom: 20px;
      &:nth-child(odd) {
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 992px) {
      margin-right: 0;
      margin-bottom: 15px;
      flex: 0 0 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .buttonElement {
    margin-right: 10px;
    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 0;
      margin-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 992px) {
    flex: 0 0 100%;
  }
}

.rightWrapper {
  margin-right: auto;
  display: flex;
  @media (max-width: 992px) {
    margin: 0;
    > * {
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &.rightWrapperMinWidth {
    min-width: 33%;
  }
  > * {
    width: 100%;
    &:first-child {
      margin-left: 15px;
    }
  }

  @media (max-width: 992px) {
    flex: 0 0 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    > * {
      width: 100%;
      margin-left: 10px;
      &:last-child {
        margin-left: 0;
        margin-bottom: 15px;
      }
    }
  }
}

.activeFilters {
  margin-top: 40px;
  flex: 100%;
  @media (max-width: 768px) {
    margin-top: 15px;
    margin-bottom: -15px;
  }
}

.activeFilterButton {
  height: 21px;
  margin-right: 10px;
  margin-bottom: 15px;
  background-color: $gray;
  border-radius: 20px !important;
  border: 1px solid $gray !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
}

.searchExposedFilter {
  max-width: 160px;
}

.exposedInput {
  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
  }
}

.filtersWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    &,
    .rightWrapper,
    .leftWrapper {
      flex: 1;
      margin: 0;
    }
    .leftWrapper {
      margin-bottom: 15px;
    }
  }
}

.noActiveFilter {
  .topWrapper {
    border: 0;
    padding: 0;
  }
}

.sortDropdown {
  @media (min-width: 768px) {
    right: 0;
  }
}

.HerarichyWrapper {
  display: flex;
  @media (max-width: 576px) {
    flex: 1 1 auto;
    flex-wrap: wrap;
  }
}

.select {
  flex: 0 0 50%;
  &.fullWidth {
    flex: 0 0 100%;
  }
  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
}

.lastSelect {
  margin-left: 20px;
  @media (max-width: 576px) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.datePickerWrapper {
  position: relative;
  .datePicker {
    width: 100%;
    input {
      text-align: center !important;
      &:focus-visible {
        outline: unset;
      }
    }
  }

  .resetDate {
    position: absolute;
    top: 15px;
    right: 15px;
    background: unset;
    padding: 0;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: $secondary;
      }
    }
  }
}
