
    @import "variables";
    @import "mixin";
    
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.datepickerWrapper {
  position: relative;
  display: inline-block;

  &.focused {
    .placeHolder {
      opacity: 0;
    }
  }
}

.datepickerInput {
  height: 40px;
  border: 0;
  padding: 5px;
  background-color: $gray;
  border-radius: 21px;
}

.placeHolder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.25s;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  pointer-events: none;
  color: $gray;

  @media (max-width: 767px) {
    justify-content: unset;
    left: 25px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.datepickerInputWhite {
  height: 40px;
  text-align: start;

  + .placeHolder {
    left: 15px;
    right: auto;
  }
}

.customInput {
  border-radius: 12px;
  height: 40px;
  color: $link-color;
  background-color: $gray;

  svg {
    margin-left: 8px;
  }

  &:hover,
  &:focus {
    background-color: $gray;
  }
  &.roundedVariant {
    height: 40px;
    background-color: $gray;
    border: 1px solid $gray;
    width: 100%;
    font-weight: 500;
    text-align: start;
    position: relative;
    font-size: 16px;
    svg {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      fill: $secondary;
      path {
        fill: $secondary;
      }
    }
    &:hover,
    &:focus {
      background-color: $gray;
    }
  }

  &.whiteVariant {
    height: 40px;
    background-color: $white;
    border: 1px solid $gray;
    width: 100%;
    font-weight: 500;
    text-align: start;
    position: relative;
    svg {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  svg {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &:hover,
  &:focus {
    background-color: $white;
    color: $black;
  }

  svg {
    fill: $primary;
    pointer-events: none;

    path {
      fill: $primary;
    }
  }
}
